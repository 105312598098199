<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <v-row align="center" justify="center">
          <h3 class="mr-3 text--bold my-5"> اختيار التاريخ </h3>
        </v-row>
        <v-form v-model="isFormValidAdd">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-menu v-model="menuAccountStartDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="data.date" :rules="addRules.required" label="التاريخ" outlined readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="data.date" @input="menuAccountStartDate = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-dialog ref="dialog" v-model="menuTime" :return-value.sync="data.time" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="data.time" label="اختيار الوقت" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="menuTime" v-model="data.time" full-width>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="menuTime = false"> الغاء </v-btn>
                  <v-btn color="primary white--text" @click="$refs.dialog.save(data.time)"> تم </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-text-field v-model="data.class_study" label="الدرس" hint="مثال: الدرس الاول" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-autocomplete v-model="data.chooseClass" :items="class_select" :loading="classLoading" :item-text="
                (item) => item.class_name + ' - ' + item.leader
              " :rules="addRules.classRules" clearable label="الصف والشعبة" outlined return-object></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-btn depressed color="primary" :disabled="!isFormValidAdd" @click="goToAbsensePage"> عرض </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import api from '@/api/api'

export default {
  data() {
    return {

      menuTime: false,

      isFormValidAdd: false,

      menuAccountStartDate: false,

      class_select: [],

      leader_select_filter: [],

      data: {
        chooseClass: null,
        chooseLeader: null,
        class_study: null,
        date: this.getCurrentDate(),
        time: this.timeNow()
      },

      classLoading: false,

      addRules: {
        classRules: [value => !!value || 'يجب اختيار الصف'],
        leaderRules: [value => !!value || 'يجب اختيار الشعبة'],
        date: [value => !!value || 'يجب اختيار التاريخ'],
        required: [value => !!value || 'الحقل مطلوب'],
      },
      selected_class_school: {},
    }
  },
  created() {
    this.getClassSchool()
  },
  methods: {
    goToAbsensePage() {
      this.$router.push(`/lectureTeacher/add/date/${this.data.date}/time/${this.toTimestamp(this.data.date + " " + this.data.time)}/class_school/${this.data.chooseClass.class_name + "_" + this.data.chooseClass.leader}/class_school_id/${this.data.chooseClass._id}/class_study/${this.data.class_study}`)
    },
    getCurrentDate() {
      const d = new Date()
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) {
        month = `0${month}`
      }
      if (day.length < 2) {
        day = `0${day}`
      }


      return [year, month, day].join('-')
    },

    timeNow() {
      var d = new Date(),
        h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
        m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
      return h + ':' + m;
    },

    toTimestamp(strDate) {
      var datum = Date.parse(strDate);
      return datum;
    },

    async getClassSchool() {
      this.classLoading = true

      const response = await api.getClassSchool()
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.classLoading = false

        this.class_select = response.data.results
        // this.leader_select = response.data.results
      }
    },
  },
}
</script>
